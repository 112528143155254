<template>
    <nav class="bg-red-900">
        <!-- Primary Navigation Menu -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex">
                    <div class="shrink-0 flex items-center text-white font-extrabold text-lg">
                       Admin Options:
                    </div>

                    <!-- Navigation Links -->
                    <div class="hidden space-x-8 sm:-my-px sm:ms-10 md:flex">
                        <NavLink
                            v-if="$page.props.auth.logged_in && ($page.props.auth.user.roles.includes('Super Admin') || $page.props.auth.user.permissions.includes('admin spaces'))"
                            :href="route('dashboard')"
                            :active="route().current('dashboard')">
                            Dashboard
                        </NavLink>

                        <NavLink
                            v-if="$page.props.auth.logged_in && ($page.props.auth.user.roles.includes('Super Admin') || $page.props.auth.user.permissions.includes('admin spaces'))"
                            :href="route('spaces')" :active="route().current('spaces')">
                            Tables
                        </NavLink>

                        <NavLink
                            v-if="$page.props.auth.logged_in && ($page.props.auth.user.roles.includes('Super Admin') || $page.props.auth.user.permissions.includes('admin events'))"
                            :href="route('events')" :active="route().current('events')">
                            Events
                        </NavLink>
                    </div>
                </div>

                <!-- Hamburger -->
                <div class="-me-2 flex items-center md:hidden">
                    <button
                        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                        @click="showingNavigationDropdown = ! showingNavigationDropdown">
                        <svg
                            class="h-6 w-6"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                            <path
                                :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}"
             class="md:hidden">
            <div class="pt-2 pb-3 space-y-1">
                <ResponsiveNavLink :href="route('bookings.home')" :active="route().current('home')">
                    Home
                </ResponsiveNavLink>
                <ResponsiveNavLink
                    v-if="$page.props.auth.logged_in && ($page.props.auth.user.roles.includes('Super Admin') || $page.props.auth.user.permissions.includes('admin spaces'))"
                    :href="route('spaces')" :active="route().current('dashboard')">
                    Dashboard
                </ResponsiveNavLink>

                <ResponsiveNavLink
                    v-if="$page.props.auth.logged_in  && ($page.props.auth.user.roles.includes('Super Admin') || $page.props.auth.user.permissions.includes('admin spaces'))"
                    :href="route('spaces')"
                    :active="route().current('spaces')">
                    Tables
                </ResponsiveNavLink>

                <ResponsiveNavLink
                    v-if="$page.props.auth.logged_in  && ($page.props.auth.user.roles.includes('Super Admin') || $page.props.auth.user.permissions.includes('admin events'))"
                    :href="route('events')"
                    :active="route().current('events')">
                    Events
                </ResponsiveNavLink>
            </div>
        </div>
    </nav>
</template>

<script setup>
    import ResponsiveNavLink from "../../InertiaComponents/ResponsiveNavLink.vue";
    import NavLink           from "../../InertiaComponents/NavLink.vue";
    import {ref}             from "vue";

    const showingNavigationDropdown = ref(false);
</script>
