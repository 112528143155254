<template>
    <nav class="bg-black">
        <!-- Primary Navigation Menu -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex">
                    <!-- Logo -->
                    <div class="shrink-0 flex items-center">
                        <Link :href="route('bookings.home')">
                            <ApplicationMark class="block h-9 w-auto"/>
                        </Link>
                    </div>

                    <!-- Navigation Links -->
                    <div class="hidden space-x-8 sm:-my-px sm:ms-10 md:flex">
                        <NavLink :href="route('bookings.home')" :active="route().current('home')">
                            Home
                        </NavLink>

                        <NavLink v-if="!$page.props.auth.logged_in" :href="route('login')"
                                 :active="route().current('login')">
                            Login
                        </NavLink>

                        <NavLink v-if="!$page.props.auth.logged_in" :href="route('register')"
                                 :active="route().current('register')">
                            Register
                        </NavLink>

                        <NavLink v-if="$page.props.auth.logged_in" :href="route('my-bookings')"
                                 :active="route().current('my-bookings')">
                            My Bookings
                        </NavLink>

                        <a :href="subscriptionsUrl"
                           class="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-md font-medium leading-5 text-white hover:text-gray-300 hover:border-gray-300 focus:outline-none focus:text-gray-400 focus:border-gray-300 transition duration-150 ease-in-out">
                            Subscriptions
                        </a>
                        <a href="https://thenerdhut.co.uk"
                           class="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-md font-medium leading-5 text-white hover:text-gray-300 hover:border-gray-300 focus:outline-none focus:text-gray-400 focus:border-gray-300 transition duration-150 ease-in-out">
                            Store
                        </a>
                    </div>
                </div>

                <div v-if="$page.props.auth.logged_in" class="hidden md:flex sm:items-center sm:ms-6">
                    <!-- Settings Dropdown -->
                    <div class="ms-3 relative">
                        <Dropdown align="right" width="48">
                            <template #trigger>
                                <button v-if="$page.props.jetstream.managesProfilePhotos"
                                        class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition">
                                    <img class="h-8 w-8 rounded-full object-cover"
                                         :src="$page.props.auth.user.profile_photo_url"
                                         :alt="$page.props.auth.user.name">
                                </button>

                                <span v-else class="inline-flex rounded-md">
                                    <button type="button"
                                            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none focus:bg-gray-50 active:bg-gray-50 transition ease-in-out duration-150">
                                        {{ $page.props.auth.user.name }}

                                        <svg class="ms-2 -me-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                             stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                        </svg>
                                    </button>
                                </span>
                            </template>

                            <template #content>
                                <!-- Account Management -->
                                <div class="block px-4 py-2 text-xs text-gray-400">
                                    Manage Account
                                </div>

                                <DropdownLink :href="route('profile.show')">
                                    Profile
                                </DropdownLink>

                                <div class="border-t border-gray-200"/>

                                <!-- Authentication -->
                                <form @submit.prevent="logout">
                                    <DropdownLink as="button">
                                        Log Out
                                    </DropdownLink>
                                </form>
                            </template>
                        </Dropdown>
                    </div>
                </div>

                <!-- Hamburger -->
                <div class="-me-2 flex items-center md:hidden">
                    <button
                        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                        @click="showingNavigationDropdown = ! showingNavigationDropdown">
                        <svg
                            class="h-6 w-6"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                            <path
                                :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}"
             class="md:hidden">
            <div class="pt-2 pb-3 space-y-1">
                <ResponsiveNavLink :href="route('bookings.home')" :active="route().current('bookings.home')">
                    Home
                </ResponsiveNavLink>

                <ResponsiveNavLink v-if="!$page.props.auth.logged_in" :href="route('login')"
                                   :active="route().current('login')">
                    Login
                </ResponsiveNavLink>

                <ResponsiveNavLink v-if="!$page.props.auth.logged_in" :href="route('register')"
                                   :active="route().current('register')">
                    Register
                </ResponsiveNavLink>

                <ResponsiveNavLink v-if="$page.props.auth.logged_in" :href="route('my-bookings')"
                                   :active="route().current('my-bookings')">
                    My Bookings
                </ResponsiveNavLink>

                <ResponsiveNavLink as="button"
                                   @click="goToStore()">
                    Store
                </ResponsiveNavLink>
            </div>

            <!-- Responsive Settings Options -->
            <div v-if="$page.props.auth.logged_in" class="pt-4 pb-1 border-t border-gray-200">
                <div class="flex items-center px-4">
                    <div>
                        <div class="font-medium text-base text-white">
                            {{ $page.props.auth.user.name }}
                        </div>
                        <div class="font-medium text-sm text-gray-200">
                            {{ $page.props.auth.user.email }}
                        </div>
                    </div>
                </div>

                <div class="mt-3 space-y-1">
                    <ResponsiveNavLink :href="route('profile.show')" :active="route().current('profile.show')">
                        Profile
                    </ResponsiveNavLink>

                    <!-- Authentication -->
                    <form method="POST" @submit.prevent="logout">
                        <ResponsiveNavLink as="button">
                            Log Out
                        </ResponsiveNavLink>
                    </form>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
    import ResponsiveNavLink from "../../InertiaComponents/ResponsiveNavLink.vue";
    import ApplicationMark   from "../../InertiaComponents/ApplicationMark.vue";
    import NavLink           from "../../InertiaComponents/NavLink.vue";
    import Dropdown          from "../../InertiaComponents/Dropdown.vue";
    import DropdownLink      from "../../InertiaComponents/DropdownLink.vue";
    import {ref}             from "vue";
    import {Link, router}    from '@inertiajs/vue3';

    const showingNavigationDropdown = ref(false);
    const subscriptionsUrl = import.meta.env.VITE_SUBSCRIPTIONS_APP_URL

    const logout = () => {
        router.post(route('logout'), {}, {
            onFinish: () => {
                window.location.reload()
            }
        });
    };
</script>
